import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AuthLeftNav from '../components/layout/AuthLeftNav';
import LanguageSelector from '../components/shared/LanguageSelector';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { layoutMarginsAtom } from '../recoil/atoms/Layout';
import { FCWithChildren } from '../types/FCWithChildren';
import IntercomUtils from '../utils/IntercomUtils';
import i18next from 'i18next';
import HelpTrigger from '../components/layout/top-menu/HelpTrigger';
import useFallbackLanguage from '../hooks/useFallbackLanguage';

const AuthLayout: FCWithChildren = (props) => {
  const { children } = props;
  const setLayoutMargins = useSetRecoilState(layoutMarginsAtom);
  const { t } = useTranslation('auth');
  const language = useFallbackLanguage();

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setLayoutMargins('ml-auth-sidebar');

    return () => setLayoutMargins('');
  }, [setLayoutMargins]);

  useEffect(() => {
    IntercomUtils.showDefaultLauncher();
  }, []);

  return (
    <>
      <aside className="w-auth-sidebar bg-background-sidebar fixed flex h-full flex-col items-center shadow">
        <AuthLeftNav />
      </aside>

      <div className="ml-auth-sidebar relative flex flex-1 flex-col">
        <Suspense fallback={<PageLoader loading isSuspense />}>
          <div className="flex h-full flex-col p-8" data-cy="login-flow">
            <div className="fixed right-8 flex items-center gap-2">
              <HelpTrigger />
              <div className="w-40">
                <LanguageSelector value={language} />
              </div>
            </div>
            <div className="flex flex-grow flex-col items-center justify-center">{children}</div>

            <div className="pb-4 text-center font-medium" data-cy="legal-links">
              <NavLink to="/legal/terms-and-conditions" target="_blank" data-cy="terms-and-conditions">
                <span className="cursor-pointer hover:underline">{t('common.tc')}</span>
              </NavLink>
              <span className="mx-2"> | </span>
              <NavLink to="/legal/data-protection-information" target="_blank" data-cy="data-protection-information">
                <span className="cursor-pointer hover:underline">{t('common.dpi')}</span>
              </NavLink>
            </div>
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default AuthLayout;
