import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../recoil/atoms/Theme';

const AuthLeftNav: FC = () => {
  const currentTheme = useRecoilValue(themeAtom);
  const { t } = useTranslation('common');

  return (
    <div
      className="bg-primary-1 relative flex h-screen w-full flex-col bg-bottom bg-no-repeat pt-12"
      style={{
        backgroundImage: `url(${currentTheme?.assets.image.authBg})`,
        backgroundSize: '100%',
      }}
    >
      <div className="mt-24 h-screen w-full">
        {currentTheme && (
          <div className="mx-12 mb-8 flex flex-col items-center justify-center">
            <img
              className="min-w-48 object-cover"
              data-cy="left-nav-platform-logo"
              src={currentTheme.assets.image.logoFullDark}
              alt="Platform logo"
            />
            {currentTheme.hasSubHeading && (
              <div className="text-dpm-16 text-rainbow-gradient ml-7 mt-4 text-center font-medium" lang="en">
                {t('slogan')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthLeftNav;
